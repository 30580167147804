<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :editTitle="'会员卡'"
      :buttonShow="false"
      isInline
      :isSelectShow="false"
    >
      <div slot="toolSub" selecTionArr="">
        <el-button type="warning" @click="exportExcel" icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
      <div class="search" slot="tool">
        <div class="searchInput el-input-group" style="width: 500px">
          <div class="el-input-group__prepend">起始日期</div>
          <el-date-picker
            v-model="dateArr"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
          >
          </el-date-picker>
        </div>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    const self = this;
    return {
      remote: api.common,
      apiName: "/vip/consume/merchantBill",
      searchQuery: { startDate: "", endDate: "", refurbishNum: 0 },
      dateArr: [
        self.$test.formatDate(new Date(), 1),
        self.$test.formatDate(new Date(), 1),
      ],

      dialogVisible: false,
      loading: false,
      searchControl: {
        firstSoltSpan: [0, 0, 0, 0],
        secondSoltSpan: [3, 3, 3, 3],
        thirdSoltSpan: [21, 21, 21, 21],
      },
      props: [
        {
          label: "商户姓名",
          prop: "merchantPersonnelName",
          align: "center",
        },
        {
          label: "摊位号",
          prop: "merchantStallNo",
          align: "center",
        },
        {
          label: "收入",
          prop: "income",
          align: "center",
        },
        {
          label: "操作",
          prop: "detail",
          align: "center",
          type: "operationLink",
          width: 160,
          formatter: function (row) {
            const buttonList = [
              {
                text: "详情",
                size: "mini",
                data: row,
                show: true,
                icon: "",
                type: "primary",
                handler: function () {
                  self.toConsume(row);
                },
              },
            ];
            return buttonList;
          },
        },
      ],
      propsFormData: {
        idCard: "",
      },
      vipInfo: {
        name: "",
        cardNo: "",
        status: "",
        money: "",
        returnMoney: "",
      },
    };
  },
  watch: {
    dateArr(e) {
      this.searchQuery.startDate = e[0];
      this.searchQuery.endDate = e[1];
    },
  },
  computed: {},
  created() {},
  methods: {
    // 前往消费记录
    toConsume(row) {
      const data = {
        startDate: `${this.dateArr[0]} 00:00:00`,
        endDate: `${this.dateArr[1]} 23:59:59`,
        merchantPersonnelName: row.merchantPersonnelName,
        merchantStallNo: row.merchantStallNo,
      };
      this.$router.push({
        path: "consume",
        query: { data: encodeURIComponent(JSON.stringify(data)) },
      });
    },
    // 导出
    async exportExcel() {
      const { searchQuery } = this;
      await api.common.download({
        apiName: "/vip/consume/merchantBillExportExcel",
        ...searchQuery,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
